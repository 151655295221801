import React, { useState } from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import List from "../components/organisms/lists/List"
import Seo from "../components/seo"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { TabPanel, a11yProps } from "../components/organisms/tab-panel/TabPanel"

const TabsStyle = styled(Tabs)`
  margin-bottom: 1rem;
  border-bottom: 1px solid #f3f3f3;
`

const DistributorPage = ({ data }) => {
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const distributorNodes = useStaticQuery(graphql`
    query {
      bg: allContentfulDistributor(
        filter: { node_locale: { eq: "bg" } }
        sort: { fields: title }
      ) {
        edges {
          node {
            title
            summary {
              summary
            }
            slug
            active
          }
        }
      }
    }
  `)
  const distributors = distributorNodes.bg.edges.map(edge => edge.node)
  const distActive = distributors.filter(dist => {
    if (dist.active) return true
    else return false
  })

  return (
    <Layout>
      <Seo title="Distributors" />
      <article className="container section--spacing">
        <div className="row">
          <div className="col-md-12">
            <TabsStyle
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Активни" {...a11yProps(0)} />
              <Tab label="Всички" {...a11yProps(1)} />
            </TabsStyle>
            <div className="row">
              <div className="col-md-8">
                <TabPanel value={tabValue} index={0}>
                  <List icon items={distActive} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <List icon items={distributors} />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
export default DistributorPage
